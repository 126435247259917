import Vue from 'vue'
import Router from 'vue-router'

import qyIndex from './qyIndex.vue'
import qyApply from "./qyApply.vue"
import qyPlan from "./qyPlan.vue"
import qyNews from "./qyNews.vue"
import qyHelp from "./qyHelp.vue"
import qyContact from "./qyContact.vue"
import qyNewsDetail from "./qyNewsDetail.vue"
// import saasSubscribeTab from "../project/common/views/saasSubscribe/saasSubscribeTab.vue"

Vue.use(Router)


export default new Router({
    routes: [

        { path: '*', redirect: '/qy' },
        {//系统入口页面，
            path: '/qy/:searchValue?',
            name: 'qy',
            props: true,
            meta: {
                title: '全用工首页'
            },
            component: qyIndex

        },{
            path: '/qyApply/:searchValue?',
            name: 'qyApply',

            meta: {
                title: '产品介绍'
            },
            component: qyApply
        },{
            path: '/qyPlan/:searchValue?',
            name: 'qyPlan',

            meta: {
                title: '解决方案'
            },
            component: qyPlan
        },{
            path: '/qyNews/:searchValue?',
            name: 'qyNews',

            meta: {
                title: '行业动态'
            },
            component: qyNews
        },{
            path: '/qyNewsDetail/:id?',
            name: 'qyNewsDetail',
            props: true,
            meta: {
                title: '新闻详情'
            },
            component: qyNewsDetail

        },{
            path: '/qyContact/:searchValue?',
            name: 'qyContact',

            meta: {
                title: '联系我们'
            },
            component: qyContact
        },{
            path: '/qyHelp/:searchValue?',
            name: 'qyHelp',

            meta: {
                title: '帮助中心'
            },
            component: qyHelp
        }
    ]
})
