<template>
    <div id="pathMap"  :style="mapStyle">
        
    </div>
    
</template>

<script>
    import BMap from 'BMap'
    import BMapLib from 'BMapLib'
    import vhcAPI from "../api/vhcAPI.js";
    import aEntityAPI from "@/api/aEntityAPI.js";
    import {
        bmapMixin
    } from "@/mixins/bmap-mixins"
 
 
    import {
        mapActions,
        mapGetters
    } from 'vuex';

    export default {
        mixins: [bmapMixin],
        props: {
          
        },
        computed:{
           
            mapStyle:function(){
                return this.maxMapStyle
                
            }
        },
        data() {
            return {
                logoImg:require('../../../assets/yjy/map_logo.png'),
                maxMapStyle: {
                    height: '720px',
                    width: (document.body.clientWidth )+ 'px'
                },
                
            }
        },
        methods: {
            ...mapActions([
                "page/closeCurrentPage"
            ]),
           
    



        },
        mounted() {
            this.bmapInit('pathMap');
            var map = this.bmap_map;
            map.enableScrollWheelZoom();

            


            map.centerAndZoom(new BMap.Point(118.412173, 31.335549), 17);
            
      
            var point = new BMap.Point(118.412173, 31.335549);
            var myIcon = new BMap.Icon(require('../../../assets/yjy/map_view_point.png'), new BMap.Size(38, 48), {
                    offset: new BMap.Size(0, 0),
                    imageOffset: new BMap.Size(0, 0)
                });
                var marker = new BMap.Marker(point, {
                    title: '安徽共生研究院公司',
                    icon: myIcon
                });

                
       
               
                var label = new BMap.Label("安徽共生研究院公司", {
                    offset: new BMap.Size(-115, -130)
                });
               
               label.setStyle({
                    backgroundColor:"0",
                    border:"0",
                });
                label.setContent('<div class="map_point_view">'+
            '<div class="map_point_view_title">安徽共生研究院公司</div>'+
            '<div class="map_point_view_info">'+
                '<img src="'+this.logoImg+'" alt="" class="map_point_view_info_img">'+
                '<div class="map_point_view_info_item">'+
                    '<div class="map_point_view_info_item_lab">电话：400-111-5856</div>'+
                    '<div class="map_point_view_info_item_lab">地址：芜湖镜湖区绿地新都会</div>'+
                    '<div class="map_point_view_info_item_lab">(黄山东路)C座15楼</div> '+
                '</div>'+
            '</div>'+
        '</div>');
      
                    // marker.setLabel(label);
                this.bmap_map.addOverlay(marker);
                     
        },


    }
</script>

<style>
    

    .map_point_view{
        width: 271px;
        height: 127px;
        display: flex;
        flex-direction: column;
        background-image: url("../../../assets/yjy/map_view_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;

       
    }

    .map_point_view_title{
       font-size: 16px;
font-family: SourceHanSansCN-Regular, SourceHanSansCN;
font-weight: 400;
color: #333333;
line-height: 24px;
       font-weight: 500;
       margin-left: 20px;
       margin-top: 10px;
       margin-bottom: 10px;
    }

    .map_point_view_info{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 20px;
    }

    .map_point_view_info_img{
        width: 50px;
        height: 50px;
        margin-right: 7px;

    }

    .map_point_view_info_item{
        display: flex;
        flex-direction: column;
        width: 240px;
    }

    .map_point_view_info_item_lab{
        width: 200px;
      
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        
    }
</style>