var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"saas_index_body"},[_c('div',{staticClass:"title_banner",attrs:{"id":"home"}},[_c('div',{staticClass:"title_banner_body"},[_c('el-carousel',{attrs:{"interval":5000,"height":"330px"}},[_c('el-carousel-item',[_c('div',{staticClass:"home_banner_bg"},[_c('img',{staticClass:"home_banner_bg_imgB",attrs:{"src":require("../assets/qyImg/jjfa.png")}})])])],1)],1),_vm._m(0)]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner_top"},[_c('div',{staticClass:"introduce"},[_c('span',{staticClass:"introduce_title1"},[_vm._v("深耕劳务行业，助力数字化转型解决方案")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"planCss"},[_c('div',{staticClass:"plandetailCss"},[_c('div',{staticClass:"planlist_tittle"},[_c('span',[_vm._v("人力资源服务机构的")]),_c('span',{staticStyle:{"color":"#396AFD"}},[_vm._v("核心竞争力")])]),_c('div',{staticClass:"planlistCss"},[_c('div',{staticClass:"planTextCss"},[_c('span',{staticClass:"text1Css"},[_vm._v("解除第三方平台限制，全用工招聘线上化")]),_c('span',{staticClass:"text2Css"},[_vm._v("通过全用工招聘平台，借助社交裂变的玩法对职位进行传播、推广，开辟招聘新渠道，快速打造人才流量的集中地，解决招聘难题。")])]),_c('img',{staticClass:"planImgCss",attrs:{"src":require("../assets/qyImg/plan1.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introCss"},[_c('div',{staticClass:"introDetailCss"},[_c('img',{staticClass:"planImgCss",attrs:{"src":require("../assets/qyImg/plan2.png"),"alt":""}}),_c('div',{staticClass:"planTextCss"},[_c('span',{staticClass:"text1Css"},[_vm._v("规范内部业务流程，管理数字化")]),_c('span',{staticClass:"text2Css"},[_vm._v("线下门店接入数字化管理，随时把控员工动态，完善内部管理体系。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introCss",staticStyle:{"background":"#F8F9FA"}},[_c('div',{staticClass:"introDetailCss"},[_c('div',{staticClass:"planTextCss"},[_c('span',{staticClass:"text1Css"},[_vm._v("保护公司核心资产，沉淀私域流量")]),_c('span',{staticClass:"text2Css"},[_vm._v("通过全用工系统，将劳务人员留存在企业专属的人才库中，沉淀为私域流量并 可循环免费使用。")])]),_c('img',{staticClass:"planImgCss",attrs:{"src":require("../assets/qyImg/plan3.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introCss"},[_c('div',{staticClass:"introDetailCss"},[_c('img',{staticClass:"planImgCss",attrs:{"src":require("../assets/qyImg/plan4.png"),"alt":""}}),_c('div',{staticClass:"planTextCss"},[_c('span',{staticClass:"text1Css"},[_vm._v("提高门店服务品质，口碑品牌化")]),_c('span',{staticClass:"text2Css"},[_vm._v("人群换工作频率高，想要“回头客”则需从职工角度提高服务品质，树立 企业品牌形象。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"worthCss"},[_c('div',{staticClass:"worthDetailCss"},[_c('span',{staticClass:"worthText1"},[_vm._v("节省招聘费用")]),_c('span',{staticClass:"worthText2"},[_vm._v("加速人才获取")]),_c('span',{staticClass:"worthText3"},[_vm._v("人才资源合理利用")]),_c('span',{staticClass:"worthText4"},[_vm._v("科学构建人才战略")]),_c('span',{staticClass:"worthText5",staticStyle:{"font-size":"28px","color":"#FFFFFF"}},[_vm._v("方案价值")])])])
}]

export { render, staticRenderFns }