<template>
  <div class="saas_index_body">
    <div id="home" class="title_banner">
      <div class="title_banner_body">
        <el-carousel :interval="5000" height="330px">
          <el-carousel-item>
            <div class="home_banner_bg">
              <img class="home_banner_bg_imgB" src="../assets/qyImg/jjfa.png" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="banner_top">
        <div class="introduce">
          <span class="introduce_title1">深耕劳务行业，助力数字化转型解决方案</span>
        </div>
      </div>

    </div>
    <div class="planCss">
      <div class="plandetailCss">
        <div class="planlist_tittle">
          <span>人力资源服务机构的</span>
          <span style="color:#396AFD">核心竞争力</span>
        </div>
        <div class="planlistCss">
          <div class="planTextCss">
            <span class="text1Css">解除第三方平台限制，全用工招聘线上化</span>
            <span class="text2Css">通过全用工招聘平台，借助社交裂变的玩法对职位进行传播、推广，开辟招聘新渠道，快速打造人才流量的集中地，解决招聘难题。</span>
          </div>
          <img class="planImgCss" src="../assets/qyImg/plan1.png" alt="">
        </div>
      </div>
    </div>
    <div class="introCss">
      <div class="introDetailCss">
        <img class="planImgCss" src="../assets/qyImg/plan2.png" alt="">
        <div class="planTextCss">
          <span class="text1Css">规范内部业务流程，管理数字化</span>
          <span class="text2Css">线下门店接入数字化管理，随时把控员工动态，完善内部管理体系。</span>
        </div>
      </div>
    </div>
    <div class="introCss" style="background: #F8F9FA;">
      <div class="introDetailCss">
        <div class="planTextCss">
          <span class="text1Css">保护公司核心资产，沉淀私域流量</span>
          <span class="text2Css">通过全用工系统，将劳务人员留存在企业专属的人才库中，沉淀为私域流量并 可循环免费使用。</span>
        </div>
        <img class="planImgCss" src="../assets/qyImg/plan3.png" alt="">
      </div>
    </div>
    <div class="introCss">
      <div class="introDetailCss">
        <img class="planImgCss" src="../assets/qyImg/plan4.png" alt="">
        <div class="planTextCss">
          <span class="text1Css">提高门店服务品质，口碑品牌化</span>
          <span class="text2Css">人群换工作频率高，想要“回头客”则需从职工角度提高服务品质，树立 企业品牌形象。</span>
        </div>
      </div>
    </div>
    <div class="worthCss">
      <div class="worthDetailCss">
        <span class="worthText1">节省招聘费用</span>
        <span class="worthText2">加速人才获取</span>
        <span class="worthText3">人才资源合理利用</span>
        <span class="worthText4">科学构建人才战略</span>
        <span class="worthText5" style="font-size: 28px;color: #FFFFFF;">方案价值</span>
      </div>
    </div>

  </div>
</template>


<script>
import aEntityAPI from "@/api/sysAPI.js";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters({
      sysConfig: "config/sysConfig",
    }),
    ...mapActions(["config/setConfig"]),
  },

  data() {
    return {
      demands: [],
      searchValue: null,
      suggestPks: [],
      suggestWhs: [],
      saasImg: require("@/assets/saas_logo.png"),
      isLogin: false,
      showExpect: false,
      titleOrRemarkColor: false,
      searchIndex: 1,
      parkList: [1, 2, 3, 4],
      cityNum: 0,
      parkNum: 0,
      areaNum: 0,
      areaLkNum: 0,
      planList: [
        {
          id: 1,
          imgUrl: require("@/assets/qyImg/plan1.png"),
          title: "全用工招聘",
          detail: "专属数字化招聘渠道，聚拢全用工私域流量配置专属后台，确保数据安全私密"
        },
        {
          id: 2,
          imgUrl: require("@/assets/qyImg/plan2.png"),
          title: "分销裂变",
          detail: "人脉“滚雪球”，以人带人人人都是平台推广员"
        },
        {
          id: 3,
          imgUrl: require("@/assets/qyImg/plan3.png"),
          title: "在线管理",
          detail: "员工管理SaaS化，实时掌握员工动态，支持在线发放工资、奖金、借支智能生成报表,一键导出明细"
        }
      ]
    };
  },

  created() {
    this["config/setConfig"];
  },

  watch: {},

  mounted() {

  },

  methods: {



  },

  filters: {
    //文字数超出时，超出部分使用...
    ellipsisTitle(value) {
      if (!value) return "";
      if (value.length > 15) {
        return value.slice(0, 15) + "...";
      }
      return value;
    },

    ellipsisRemark(value) {
      if (!value) return "";
      if (value.length > 35) {
        return value.slice(0, 35) + "...";
      }
      return value;
    },
  },
};
</script>

<style scoped>
.betweenCss {
  justify-content: space-between;
}
.saas_index_body {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.saas_index_body .home_banner_bg {
  width: 100%;
  height: 100%;
  position: relative;
}

.saas_index_body .home_banner_bg_imgA {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.saas_index_body .home_banner_bg_imgB {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  mix-blend-mode: screen;
  z-index: 4;
  /* object-fit: contain; */
}

.saas_index_body .home_banner_bg_imgC {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  object-fit: contain;
}

.saas_index_body .title_banner {
  padding-top: 68px;
  height: 330px;
  background-color: #ffffff;
  position: relative;
}

.saas_index_body .discount {
  height: 250px;
  background-color: #0f68e8;
}

.saas_index_body .product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.saas_index_body .title_banner_body {
  width: 100%;
  height: 100%;
  z-index: 2;
}
.banner_top {
  width: 100%;
  position: absolute;
  top: 200px;
  z-index: 99;
  display: flex;
  justify-content: center;
  padding-left: 360px;
  padding-right: 160px;
  box-sizing: border-box;
}

.introduce {
  /* width: 450px !important; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
}

.introduce_title1 {
  font-weight: 400;
  font-size: 48px;
  color: #ffffff;
}

.introduce_title2 {
  font-weight: 350;
  font-size: 24px;
  color: #ffffff;
  margin-top: 20px;
}

.introduce_btn {
  margin-top: 45px;
  width: 182px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(24, 57, 232, 0.1);
  border-radius: 63px;
  font-weight: 500;
  font-size: 20px;
  color: #396afd;
  text-align: center;
  line-height: 60px;
}

.saas_index_body .cust_case {
  background: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.saas_index_body .cust_title {
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 30px;
  margin-bottom: 30px;
}

.saas_index_body .cust_body {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
  width: 1200px;
  height: 360px;
}
.saas_index_body .cust_body .el-carousel__arrow--left {
  left: 0;
}
.saas_index_body .cust_body .el-carousel__arrow--right {
  right: 0;
}
.saas_index_body .img_array_body {
  display: flex;
  /* background-color: #0f68e8; */
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  color: #0f68e8;
  height: 100%;
  width: 1100px;
  margin-left: 50px;
}

.saas_index_body .img_array_body_item {
  width: 230px;
  height: 90px;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  border: 1px solid #ddd;
  padding: 20px 10px;
}
.planCss {
  width: 100%;
  height: 490px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../assets/qyImg/hexin.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.plandetailCss {
  width: 1200px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 75px 0;
}

.planlist_tittle {
  font-weight: 700;
  font-size: 38px;
  color: #000000;
}

.planlistCss {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.planImgCss {
  width: 320px;
  height: 230px;
}

.planTextCss {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text1Css {
  font-weight: 700;
  font-size: 32px;
  color: #396afd;
}

.text2Css {
  font-weight: 500;
  font-size: 20px;
  color: #444444;
  margin-top: 30px;
}

.introCss {
  width: 100%;
  height: 560px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.introDetailCss {
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.worthCss {
  width: 100%;
  height: 640px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #f8faff 0%, #ffffff 100%);
}

.worthDetailCss {
  width: 1200px;
  height: 535px;
  background-image: url("../assets/qyImg/jiazhi.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-weight: 500;
  font-size: 18px;
  color: #444444;
  position: relative;
}

.worthText1 {
  position: absolute;
  top: 153px;
  left: 125px;
}
.worthText2 {
  position: absolute;
  top: 153px;
  right: 140px;
}
.worthText3 {
  position: absolute;
  right: 112px;
  bottom: 153px;
}
.worthText4 {
  position: absolute;
  left: 111px;
  bottom: 153px;
}
.worthText5 {
  position: absolute;
  top: 246px;
  left: 545px;
}
</style>