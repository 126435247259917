<template>
  <div class="saas_index_body">
    <div id="home" class="title_banner">
      <div class="title_banner_body">
        <el-carousel :interval="5000" height="330px">
          <el-carousel-item>
            <div class="home_banner_bg">
              <img class="home_banner_bg_imgB" src="../assets/qyImg/hangye.png" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="banner_top">
        <div class="introduce">
          <span class="introduce_title1">政策利好频出、行业迅速发展</span>
        </div>
      </div>

    </div>
    <div class="planCss">
      <div class="plandetailCss">
        <el-table v-loading="grid_loading" :data="dataList" @row-click="clickRow" style="width: 100%" :row-key="getRowKey" :show-header=false>
          <el-table-column prop="title" label="标题" align="center">
            <template slot-scope="scope">
              <div class="newsCss">
                <div class="newCss_tiitle">{{scope.row.title}}</div>
                <div class="newCss_detail">{{scope.row.intro}}</div>
                <div class="newCss_time">{{scope.row.releaseTime}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="coverImg" label="封面" align="center" width="340">
            <template slot-scope="scope">
              <div class="imgCss">
                <img style="height:160px;width:340px" :src="scope.row.coverImg" alt="">
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width: 100%;display: flex;margin-top: 30px;justify-content: end;">
          <el-pagination background layout="sizes, total, prev, pager, next ,jumper" :total="pagetotal" :page-sizes="[5, 10, 30, 50]" :page-size="pageSize" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page.sync="pageNo"></el-pagination>
        </div>

      </div>
    </div>

  </div>
</template>


<script>
import aEntityAPI from "@/api/sysAPI.js";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters({
      sysConfig: "config/sysConfig",
    }),
    ...mapActions(["config/setConfig"]),
  },

  data() {
    return {
      grid_loading: false,
      dataList: [],
      pageSize: 5,
      pageNo: 1,
      pagetotal: 0,
    };
  },

  created() {
    this["config/setConfig"];
  },

  watch: {},

  mounted() {
    this.gethpNewsList()
  },

  methods: {
    clickRow(row){
      console.log(row,'row');
      this.showNewDetail(row)
    },
    //新闻详情
    showNewDetail(item) {
      var url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/qy.html#/qyNewsDetail?id=" +
        item.id;
      window.open(url);
    },
    getRowKey(row) {
      return row.id
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.gethpNewsList();
    },
    handleCurrentChange(val) {
      this.pageNo = val
      this.gethpNewsList();
    },
    //获取云共享企业新闻列表
    gethpNewsList() {
      let params = {
        start: this.pageSize * (this.pageNo - 1),
        limit: this.pageSize,
        q: {
          fields: [
            "id",
            "title",
            "intro",
            "category",
            "categoryId",
            "coverImg",
            "releaseTime",
            "status",
            "content",
          ],

        },
      };
      this.grid_loading = true
      aEntityAPI.request("NewsService", "getYgxNewsList", { qp: JSON.stringify(params) })
        .then((response) => {
          this.grid_loading = false
          if (response.data.success) {
            this.dataList = response.data.data
            this.pagetotal = response.data.total;
          } else {
            this.$message.error("查询新闻出错");
          }
        });
    },
  },

  filters: {
    //文字数超出时，超出部分使用...
    ellipsisTitle(value) {
      if (!value) return "";
      if (value.length > 15) {
        return value.slice(0, 15) + "...";
      }
      return value;
    },

    ellipsisRemark(value) {
      if (!value) return "";
      if (value.length > 35) {
        return value.slice(0, 35) + "...";
      }
      return value;
    },
  },
};
</script>

<style scoped>
.betweenCss {
  justify-content: space-between;
}
.saas_index_body {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.saas_index_body .home_banner_bg {
  width: 100%;
  height: 100%;
  position: relative;
}

.saas_index_body .home_banner_bg_imgA {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.saas_index_body .home_banner_bg_imgB {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  mix-blend-mode: screen;
  z-index: 4;
  /* object-fit: contain; */
}

.saas_index_body .home_banner_bg_imgC {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  object-fit: contain;
}

.saas_index_body .title_banner {
  padding-top: 68px;
  height: 330px;
  background-color: #ffffff;
  position: relative;
}

.saas_index_body .discount {
  height: 250px;
  background-color: #0f68e8;
}

.saas_index_body .product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.saas_index_body .title_banner_body {
  width: 100%;
  height: 100%;
  z-index: 2;
}
.banner_top {
  width: 100%;
  position: absolute;
  top: 200px;
  z-index: 99;
  display: flex;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
}

.introduce {
  /* width: 450px !important; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
}

.introduce_title1 {
  font-weight: 400;
  font-size: 48px;
  color: #ffffff;
}

.introduce_title2 {
  font-weight: 350;
  font-size: 24px;
  color: #ffffff;
  margin-top: 20px;
}

.introduce_btn {
  margin-top: 45px;
  width: 182px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(24, 57, 232, 0.1);
  border-radius: 63px;
  font-weight: 500;
  font-size: 20px;
  color: #396afd;
  text-align: center;
  line-height: 60px;
}

.saas_index_body .cust_case {
  background: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.saas_index_body .cust_title {
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 30px;
  margin-bottom: 30px;
}

.saas_index_body .cust_body {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
  width: 1200px;
  height: 360px;
}
.saas_index_body .cust_body .el-carousel__arrow--left {
  left: 0;
}
.saas_index_body .cust_body .el-carousel__arrow--right {
  right: 0;
}
.saas_index_body .img_array_body {
  display: flex;
  /* background-color: #0f68e8; */
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  color: #0f68e8;
  height: 100%;
  width: 1100px;
  margin-left: 50px;
}

.saas_index_body .img_array_body_item {
  width: 230px;
  height: 90px;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  border: 1px solid #ddd;
  padding: 20px 10px;
}
.planCss {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.plandetailCss {
  width: 1200px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 75px 0;
}

.newsCss {
  width: 750px;
  height: 220px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.imgCss {
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}

.newCss_tiitle {
  font-size: 20px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
}

.newCss_detail {
  height: 70px;
  font-size: 14px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis; /*然后设置文本隐藏方式*/
  /*为了实现多行文本也有效果要设置如下3个属性，具体属性的含义我还不是很清楚：*/
  display: -webkit-box;
  -webkit-line-clamp: 3; /*只显示3行，如果不设置这个属性，会出现文本被拦腰截断的现象*/
  -webkit-box-orient: vertical;
  text-align: left;
}

.newCss_time {
  font-size: 14px;
  color: #999999;
}
</style>