<template>
  <div class="saas_index_body">
    <div id="home" class="title_banner">
      <div class="title_banner_body">
        <el-carousel :interval="5000" height="330px">
          <el-carousel-item>
            <div class="home_banner_bg">
              <img class="home_banner_bg_imgB" src="../assets/qyImg/hangye.png" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="banner_top">
        <div class="introduce">
          <span class="introduce_title1">助企业建立人才私域流量池</span>
        </div>
      </div>

    </div>
    <div class="contactCss">
      <div class="contactCss_top">
        <div class="contactCss_info">
          <img class="contactCss_infoImg" src="../assets/qyImg/contact1.png" alt="">
          <div>如遇到产品使用问题，可随时与我们联系</div>
          <div style="margin-top:15px">工作时间：周一至周日 24小时在线</div>
          <div style="margin-top:15px">
            <span>客服热线：</span>
            <span style="color:#396AFD">18900535353（微信同号）</span>
          </div>
        </div>
        <div class="contactCss_info">
          <img class="contactCss_infoImg" :src="qrCode" alt="">
          <div>微信扫一扫关注小程序</div>
        </div>
      </div>
      <div class="contactCss_loaction">
        <img class="contactCss_infoImg" src="../assets/qyImg/contact2.png" alt="">
        <div>公司地址：安徽省芜湖市镜湖区绿地新都会C座15楼</div>
        <div style="width: 960px;height: 285px;margin-top:30px">
            <qy-map style="width:100%;height:100%" title=""   ref="qyMap" ></qy-map>
          </div>
      </div>
    </div>

  </div>
</template>


<script>
import aEntityAPI from "@/api/sysAPI.js";
import { mapActions, mapGetters } from "vuex";
import qyMap from '@/project/wlhy/views/qyMap.vue';

export default {
  components: {
    qyMap
  },
  computed: {
    ...mapGetters({
      sysConfig: "config/sysConfig",
    }),
    ...mapActions(["config/setConfig"]),
  },

  data() {
    return {
      qrCode:''
    };
  },

  created() {
    this["config/setConfig"];
  },

  watch: {},

  mounted() {
    this.queryCompanyConfig()
  },

  methods: {
     queryCompanyConfig() {
      aEntityAPI.request("AppConfigService", "getConfigsByClsList", {clsList: JSON.stringify(["CompanyConfig"])})
        .then((res) => {
          var CompanyConfig = res.data.data.CompanyConfig
            if(CompanyConfig.driverMiniApp){
              this.qrCode = this.__ftpPath+CompanyConfig.driverMiniApp.filePath;
            }
        });
    },
         
  },

  filters: {
    //文字数超出时，超出部分使用...
    ellipsisTitle(value) {
      if (!value) return "";
      if (value.length > 15) {
        return value.slice(0, 15) + "...";
      }
      return value;
    },

    ellipsisRemark(value) {
      if (!value) return "";
      if (value.length > 35) {
        return value.slice(0, 35) + "...";
      }
      return value;
    },
  },
};
</script>

<style scoped>
.betweenCss {
  justify-content: space-between;
}
.saas_index_body {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.saas_index_body .home_banner_bg {
  width: 100%;
  height: 100%;
  position: relative;
}

.saas_index_body .home_banner_bg_imgA {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.saas_index_body .home_banner_bg_imgB {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  mix-blend-mode: screen;
  z-index: 4;
  /* object-fit: contain; */
}

.saas_index_body .home_banner_bg_imgC {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  object-fit: contain;
}

.saas_index_body .title_banner {
  padding-top: 68px;
  height: 330px;
  background-color: #ffffff;
  position: relative;
}

.saas_index_body .discount {
  height: 250px;
  background-color: #0f68e8;
}

.saas_index_body .product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.saas_index_body .title_banner_body {
  width: 100%;
  height: 100%;
  z-index: 2;
}
.banner_top {
  width: 100%;
  position: absolute;
  top: 200px;
  z-index: 99;
  display: flex;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
}

.introduce {
  /* width: 450px !important; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
}

.introduce_title1 {
  font-weight: 400;
  font-size: 48px;
  color: #ffffff;
}

.introduce_title2 {
  font-weight: 350;
  font-size: 24px;
  color: #ffffff;
  margin-top: 20px;
}

.introduce_btn {
  margin-top: 45px;
  width: 182px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(24, 57, 232, 0.1);
  border-radius: 63px;
  font-weight: 500;
  font-size: 20px;
  color: #396afd;
  text-align: center;
  line-height: 60px;
}

.saas_index_body .cust_case {
  background: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.saas_index_body .cust_title {
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 30px;
  margin-bottom: 30px;
}

.saas_index_body .cust_body {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
  width: 1200px;
  height: 360px;
}
.saas_index_body .cust_body .el-carousel__arrow--left {
  left: 0;
}
.saas_index_body .cust_body .el-carousel__arrow--right {
  right: 0;
}
.saas_index_body .img_array_body {
  display: flex;
  /* background-color: #0f68e8; */
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  color: #0f68e8;
  height: 100%;
  width: 1100px;
  margin-left: 50px;
}

.saas_index_body .img_array_body_item {
  width: 230px;
  height: 90px;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  border: 1px solid #ddd;
  padding: 20px 10px;
}
.contactCss {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px 0;
}

.contactCss_top {
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.contactCss_info {
  width: 48%;
  height: 160px;
  background: #f2f9ff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  color: #444444;
}

.contactCss_infoImg {
  position: absolute;
  width: 65px;
  height: 65px;
  top: -32px;
}

.contactCss_loaction {
  width: 1200px;
  height: 440px;
  margin-top: 65px;
  background: #f2f9ff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  color: #444444;
}
</style>