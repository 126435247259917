<template>
  <div class="saas_body">
    <div id="saas_header" class="saas_header">
      <div class="home_header_left">
        <div class="home-logo" @click="clickHomeMenum(1)">
          全用工管理系统平台
        </div>
        <div class="home-menu">
          <ul>
            <li :class="{ active: qyIndex==1}"><a @click="clickHomeMenum(1)">首页</a></li>
            <li :class="{ active: qyIndex==2}"><a @click="clickHomeMenum(2)">产品介绍</a></li>
            <li :class="{ active: qyIndex==3}"><a @click="clickHomeMenum(3)">解决方案</a></li>
            <li :class="{ active: qyIndex==4}"><a @click="clickHomeMenum(4)">行业动态</a></li>
            <!-- <li :class="{ active: qyIndex==5}"><a @click="clickHomeMenum(5)">帮助中心</a></li> -->
            <li :class="{ active: qyIndex==6}"><a @click="clickHomeMenum(6)">关于我们</a></li>
          </ul>
        </div>
      </div>
      <div class="home_header_right">
        <div class="home-btn" v-if="user != null && user.id != null">
          <el-button type="text" class="btn1" @click="landInAction()">进入系统
          </el-button>
          <el-button type="text" style="font-size:16px;color:#000000" @click="logoutAction()">退出
          </el-button>
        </div>
        <div class="home-btn" v-else>
          <el-button type="text" class="btn1" @click="loginAction()">登录
          </el-button>
          <el-button class="btn2" @click="regAction()">注册</el-button>
        </div>
      </div>
    </div>
    <div class="saas_container">
      <router-view></router-view>
    </div>

    <div class="qy_footer">
      <div class="companyCss">
        <div class="companyCss_left">
          <span style="font-size: 28px;color: #333333;">联系我们</span>
          <span style="font-size: 14px;color: #333333;">CONTACT US</span>
          <span style="font-size: 14px;color: #999999;margin-top:25px">产品介绍·解决方案·行业动态·帮助中心·关于我们·加入我们</span>
          <div style="font-size: 14px;color: #999999;margin-top:10px">
            <span style="margin-right:60px">电话：{{ companyConfig?companyConfig.phone:''}}</span>
            <!-- <span style="margin-right:60px">{{ companyConfig.email}}</span> -->
            <span style="margin-right:60px">邮箱： {{companyConfig?companyConfig.email:''}}</span>
            <span>地址：{{ companyConfig?companyConfig.addr:''}}</span>
          </div>
          <div style="font-size: 14px;color: #999999;margin-top:10px" v-html="companyConfig?companyConfig.webLic:''"></div>
        </div>
        <div v-if="qrCode" class="companyCss_right">
          <div class="qrCodeCss">
            <img style="width:100%;heught:100%" :src="qrCode" alt="">
          </div>
          <span style="font-weight: 400;font-size: 14px;">关注小程序</span>
        </div>

      </div>
    </div>

  </div>
</template>


<script>
import aEntityAPI from "@/api/sysAPI.js";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      companyConfig:{},
      tenantDomain: "",
      qyIndex: 1,
      user: {},
      searchValue: null,
      logoUrl: "",
      config: {},
      hasUserTag: false,
      activeHeader: "header",
      qr_code: {
        img: require("@/assets/qr/qr_code.png"),
      },
      hrsaasImg: require("@/assets/hrsaas/index/hr_saas_logo.png"),
      saasImg: require("@/assets/saas_logo.png"),
      qrCode:""
    };
  },

  created() { },

  watch: {
    $route(to, from) {
      if (to.path.indexOf("qyApply") > -1) {
        // this.qyIndex = 2;
        //  if (this.$route.params && this.$route.params.index != null) {
        //    setTimeout(() =>{
        //      this.toScrollTop(this.$route.params.index);
        //    }, 50);
        //  }
        setTimeout(() => {
          this.toScrollTop(3);
        }, 50);
      } else {
        // this.qyIndex = 1;
        setTimeout(() => {
          this.toScrollTop(3);
        }, 50);
      }
    },

    sysConfig: function (nval, oval) {
      if (nval && nval.logoFile && nval.logoFile.filePath) {
        var link = document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";

        var serverHost = window.location.host;
        if (
          serverHost.indexOf("hrsaas") > -1 ||
          serverHost.indexOf("saas.gz-vip.com") > -1
        ) {
          link.href = this.hrsaasImg;
        } else if (serverHost.indexOf("saas") > -1) {
          link.href = this.saasImg;
        } else {
          link.href = this.__ftpPath + nval.logoFile.filePath;
        }
        document.getElementsByTagName("head")[0].appendChild(link);
      }
    },
  },

  methods: {
    ...mapActions(["user/logout", "page/clearPage"]),

    clickHomeMenum(index) {
      this.qyIndex = index;
      console.log(this.qyIndex, 'topindex');
      if (index == 1) {
        document.title = "全用工首页";
        this.$router.push({ name: "qy" });
      }
      if (index == 2) {
        document.title = "产品介绍";
        this.$router.push({ name: "qyApply" });
      }
      if (index == 3) {
        document.title = "解决方案";
        this.$router.push({ name: "qyPlan" });
      }
      if (index == 4) {
        document.title = "行业动态";
        this.$router.push({ name: "qyNews" });
      }
      if (index == 5) {
        document.title = "帮助中心";
        this.$router.push({ name: "qyHelp" });
      }
      if (index == 6) {
        document.title = "关于我们";
        this.$router.push({ name: "qyContact" });
      }
    },

    
    queryCompanyConfig() {
      aEntityAPI.request("AppConfigService", "getConfigsByClsList", {clsList: JSON.stringify(["CompanyConfig"])})
        .then((res) => {
            var CompanyConfig = res.data.data.CompanyConfig
            this.companyConfig = CompanyConfig
            if(CompanyConfig.driverMiniApp){
              this.qrCode = this.__ftpPath+CompanyConfig.driverMiniApp.filePath;
            }
        });
    },

    //获取产品的信息
    getProductConfig() {
      let that = this;
      aEntityAPI
        .request("UserProductSubscribeService", "saasIndexConfig", {})
        .then((res) => {
          this.grid_loading = false;

          if (res.data.success) {
            var ftpUrl = that.__ftpPath;
            if (res.data.data.saasTenantBillList != null) {
              this.billList = res.data.data.saasTenantBillList;
            }
            if (res.data.data.user != null) {
              this.user = res.data.data.user;
            }
            var dataDict = res.data.data.companyConfig;
            this.config = dataDict;
            var appList = [];

            if (dataDict.driverMiniApp != null) {
              var appMin = {
                icon: ftpUrl + dataDict.driverMiniApp.filePath,
                name: "司机小程序",
              };
              appList.push(appMin);
            }
            if (dataDict.driverAndriodApp != null) {
              var appMin = {
                icon: ftpUrl + dataDict.driverAndriodApp.filePath,
                name: "司机APP",
              };
              appList.push(appMin);
            }
            if (dataDict.shipperMiniApp != null) {
              var appMin = {
                icon: ftpUrl + dataDict.shipperMiniApp.filePath,
                name: "货主小程序",
              };
              appList.push(appMin);
            }
            if (dataDict.shipperApp != null) {
              var appMin = {
                icon: ftpUrl + dataDict.shipperApp.filePath,
                name: "货主APP",
              };
              appList.push(appMin);
            }
            if (dataDict.logoFile != null) {
              this.logoUrl = ftpUrl + dataDict.logoFile.filePath;
            }
            this.appList = appList;

            if (this.searchValue != null && this.searchValue != "") {
              document.getElementById("product_saas_my").scrollIntoView(true);
            }
          }
        })
        .catch((res) => {
          this.grid_loading = false;
        });
    },
    toScrollTop(index) {
      if (index == 0) {
        document.documentElement.scrollTop = 900;
      }
      if (index == 1) {
        document.documentElement.scrollTop = 1400;
      }
      if (index == 2) {
        document.documentElement.scrollTop = 1900;
      }
      if (index == 3) {
        document.documentElement.scrollTop = 0;
      }
    },



    logoutAction() {
      this["user/logout"]().then((data) => {
        if (data) {
          window.location.href = "/login.html";
        }
      });
    },

    //注册
    regAction() {
      // var url = window.location.protocol + "//" + window.location.host + "/login.html#/reg/saas";
      // var url = window.location.protocol + "//" + window.location.host + "/login.html#/regSaas";
      window.location.href = "login.html#/";
      // window.open(url);
    },

    //进入系统
    landInAction() {
      var url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/login.html#/login";
      window.open(url);
    },

    //登录
    loginAction() {
      window.location.href = "login.html#/login";

      // window.open(url);
    },

    
  },

  mounted() {
    if (this.$route.name == 'qyApply') {
      this.qyIndex = 2
    } else if (this.$route.name == 'qyPlan') {
      this.qyIndex = 3
    } else if (this.$route.name == 'qyNews') {
      this.qyIndex = 4
    }  else if (this.$route.name == 'qyNewsDetail') {
      this.qyIndex = 4
    }else if (this.$route.name == 'qyHelp') {
      this.qyIndex = 5
    } else if (this.$route.name == 'qyContact') {
      this.qyIndex = 6
    } else {
      this.qyIndex = 1
    }
    if (this.$route.params && this.$route.params.searchValue != null) {
      this.searchValue = this.$route.params.searchValue;
    }
    if (this.$route.params && this.$route.params.index != null) {
    }
    
    this.getProductConfig()
    this.queryCompanyConfig()
  },
};
</script>
<style>
body {
  overflow: auto;
}

.saas_body {
  height: 100%;
  background-color: #2d3a4b;
  min-width: 1200px;
}

.saas_container {
  display: flex;
  width: 100%;
  background-color: #fff;
  min-height: 500px;
}

.saas_buy_car {
  right: 10px;
  top: 45%;
  background: #fff;
  position: fixed;
  border: 2px solid #ffffff;

  width: 72px;
  height: 200px;
  background: #f5f7fa;
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 15px 0px rgba(21, 113, 179, 0.26);
  opacity: 0.97;
  border-radius: 36px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 22;
}

.saas_buy_car:hover {
  cursor: pointer;
}

.saas_buy_car_title {
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #393939;
}

.price_color {
  color: #2468f2;
}

.saas_buy_car_img {
  width: 38px;
  height: 34px;
}

.saas_header {
  height: 68px;
  background: #fff;
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  box-shadow: 0px 2px 18px 2px rgba(58, 60, 65, 0.1);
}

.home_header_left {
  margin-left: 350px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home_header_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 360px;
}

.home-logo {
  height: 66px;
  font-family: BiaoTiTi;
  color: #396afd;
  font-weight: 400;
  font-size: 24px;
  line-height: 66px;
}

.home-logo:hover {
  cursor: pointer;
}

.home-sys-name:hover {
  cursor: pointer;
}

.home-logo img {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.home-sys-name {
  font-size: 20px;
  color: #000000;
  padding-left: 20px;
  padding-right: 20px;
  font-family: ZKGDH;
  font-size: 30px;
  color: #006ce7;
  width: 290px;
}

.home-menu {
  /* width: 300px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000000;
  height: 100%;
}

.home-menu:hover {
  cursor: pointer;
}

.home-menu ul > li {
  height: 63px;
  line-height: 63px;
  margin: 0 20px;
  float: left;
  font-size: 16px;
  color: #fff;
}

.home-menu ul > li > a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
}

.home-menu ul .active > a {
  color: #396afd;
}

.home-menu ul .active {
  border-bottom: 4px solid #396afd;
}

/* .home-menu ul > li.active > a {
      border-bottom: 2px solid #409eff;
    } */

.home-btn {
  /* width: 150px; */
  display: inline-block;
  /* height: 100%;
  line-height: 60px;
  padding: 20px 0; */
}

.header-search {
  margin-right: 20px;
}

.app_body_view {
  float: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 40px;
}

.app_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-left: 20px;
}

.home-ba-info {
  font-size: 16px;
  color: white;
  margin-top: 10px;
}

.app_name {
  font-size: 16px;
  color: white;
  margin-top: 10px;
}

@font-face {
  font-family: ZKGDH;
  src: url("../font/iconfont_zkgdh.TTF");
}
.btn1 {
  margin-right: 20px !important;
  font-size: 16px !important;
  color: #000000 !important;
}
.btn2 {
  width: 96px;
  height: 40px;
  border-radius: 63px !important;
  padding: 0 !important;
  background-color: #396afd !important;
  text-align: center !important;
  line-height: 40px !important;
  font-size: 16px !important;
  color: #ffffff !important;
}

.qy_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: center; */
  height: 225px;
  background: #fff;
  border-top: 2px solid #f0f0f0;
}

.companyCss {
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.companyCss_left {
  display: flex;
  flex-direction: column;
  font-weight: 400;
}

.companyCss_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
}

.qrCodeCss {
  width: 109px;
  height: 108px;
  border: 1px solid #979ca8;
}
</style>