<template>
  <div class="saas_index_body">

    <div class="helpCss">
      <img class="helpCss_bgImg" src="../assets/qyImg/helpBg.png" alt="">
      <div class="helpDetailCss">
        <div style="font-size: 46px;color: #000000;">
          <span>请问需要什么</span>
          <span style="color:#396AFD">帮助</span>
          <span>?</span>
        </div>
        <div style="width:560px;margin-top:50px">
          <el-input placeholder="请输入搜索关键词" prefix-icon="el-icon-search" v-model="searchFrom.name" icon-class="search_icon"></el-input>
        </div>
        <div class="popularCss">
          <div style="font-weight: 600;font-size: 16px;color: #444444">热门搜索</div>
          <div class="popularCss_list">
            <div class="popularCss_item" v-for="(item,index) in popularList" :key="index">
              <img src="../assets/qyImg/yuanhuan.png" alt="">
              <span>{{item.content}}</span>
            </div>
          </div>
        </div>
        <div class="TypeCss">
          <div style="font-weight: 600;font-size: 18px;color: #444444;">常见问题</div>
          <div class="commonCss">
            <div class="commonCss_item">
              <div class="item_title">
                <img src="../assets/qyImg/helpItem.png" alt="">
                基础操作
              </div>
              <div class="item_detail">
                <div style="display:flex;align-items: center;" v-for="(item,index) in commonList" :key="index">
                  <div class="dotCss"></div>{{item.content}}
                </div>
              </div>
              <div class="moreCss">
                查看更多<img src="../assets/qyImg/lujing.png" alt="">
              </div>
            </div>
            <div class="commonCss_item">
              <div class="item_title">
                <img src="../assets/qyImg/helpItem.png" alt="">
                账号问题
              </div>
              <div class="item_detail">
                <div style="display:flex;align-items: center;" v-for="(item,index) in questList" :key="index">
                  <div class="dotCss"></div>{{item.content}}
                </div>
              </div>
              <div class="moreCss">
                查看更多<img src="../assets/qyImg/lujing.png" alt="">
              </div>
            </div>
            <div class="commonCss_item">
              <div class="item_title">
                <img src="../assets/qyImg/helpItem.png" alt="">
                猜你想问
              </div>
              <div class="item_detail">
                <div style="display:flex;align-items: center;" v-for="(item,index) in wantList" :key="index">
                  <div class="dotCss"></div>{{item.content}}
                </div>
              </div>
              <div class="moreCss">
                查看更多<img src="../assets/qyImg/lujing.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="TypeCss">
          <div style="font-weight: 600;font-size: 18px;color: #444444;">操作手册</div>
          <div class="operateCss">
            <div class="operateCss_item" v-for="(item,index) in operateList" :key="index">
              <div class="numCss">0{{index+1}}</div>
              <img class="operateImgCss" src="../assets/qyImg/jiaoji.png" alt="">
              <div class="operateCss_detail">
                <div style="font-size: 20px;color: #444444">{{item.content}}</div>
                <div style="display:flex;align-items: center;">
                  <div class="operateCss_btn" style="margin-right:25px">
                    <img src="../assets/qyImg/xiazai.png" alt="">下载
                  </div>
                  <div class="operateCss_btn">
                    <img src="../assets/qyImg/chakan.png" alt="">在线查看
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import aEntityAPI from "@/api/sysAPI.js";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters({
      sysConfig: "config/sysConfig",
    }),
    ...mapActions(["config/setConfig"]),
  },

  data() {
    return {
      grid_loading: false,
      searchFrom: {
        name: null
      },
      popularList: [
        { content: '【职位管理】如何修改职位信息？' },
        { content: '新手入门指南' },
        { content: '【职位管理】如何下线职位？' },
        { content: '如何注册账号？' },
        { content: '【职位管理】如何修改职位信息？' },
        { content: '新手入门指南' },
        { content: '【职位管理】如何下线职位？' },
        { content: '如何注册账号？' },
      ],
      commonList: [
        { content: '【职位管理】如何修改职位信息？' },
        { content: '【职位管理】如何下线职位？' },
        { content: '【职位管理】如何更改职位专区？' },
        { content: '【职位管理】如何设置热门职位？' },
      ],
      questList: [
        { content: '小程序名字是可以自行修改？' },
        { content: '如何登陆小程序管理后台？' },
        { content: '忘记了密码或账号怎么办？' },
        { content: '如何注册账号？' },
      ],
      wantList: [
        { content: '手机可以录入企业信息吗？' },
        { content: '位置置顶可以设置多少个？' },
        { content: '批量导入员工的模板是否可以修改？' },
        { content: '工资表需包含哪些内容？' },
      ],
      operateList: [
        { content: '财务专员操作手册.pdf' },
        { content: '财务专员操作手册.pdf' },
        { content: '财务专员操作手册.pdf' },
        { content: '财务专员操作手册.pdf' },
        { content: '财务专员操作手册.pdf' },
        { content: '财务专员操作手册.pdf' },
      ]

    };
  },

  created() {
    this["config/setConfig"];
  },

  watch: {},

  mounted() {
  },

  methods: {

  },

  filters: {
    //文字数超出时，超出部分使用...
    ellipsisTitle(value) {
      if (!value) return "";
      if (value.length > 15) {
        return value.slice(0, 15) + "...";
      }
      return value;
    },

    ellipsisRemark(value) {
      if (!value) return "";
      if (value.length > 35) {
        return value.slice(0, 35) + "...";
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.saas_index_body {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.helpCss {
  width: 100%;
  height: 1580px;
  margin-top: 68px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.helpCss_bgImg {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 900px;
}

.helpDetailCss {
  width: 1200px;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

/deep/ .el-input--medium .el-input__inner {
  height: 58px;
  line-height: 58px;
  font-size: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 53px;
  padding-left: 60px;
}

/deep/ .el-input .el-input__icon {
  width: 60px;
  line-height: 58px;
}

/deep/ .el-icon-search:before {
  font-size: 20px;
}

.popularCss {
  margin-top: 60px;
  width: 100%;
  padding: 30px 20px 25px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  box-sizing: border-box;
}

.popularCss_list {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.popularCss_item {
  width: 25%;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  padding: 15px 0;
}

.popularCss_item img {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.TypeCss {
  width: 100%;
  margin-top: 70px;
}

.commonCss {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.commonCss_item {
  width: 31%;
  height: 240px;
  background: #ffffff;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 30px 25px 25px;
  box-sizing: border-box;
}
.item_title {
  font-weight: 500;
  font-size: 18px;
  color: #444444;
  display: flex;
  align-items: center;
}

.item_title img {
  width: 12px;
  height: 15px;
  margin-right: 10px;
}

.item_detail {
  height: 120px;
  border-bottom: 1px solid #f0f0f0;
  padding: 20px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  color: #444444;
  box-sizing: border-box;
}

.dotCss {
  width: 3px;
  height: 3px;
  background: #396afd;
  border-radius: 50%;
  margin-right: 10px;
}

.moreCss {
  display: flex;
  align-items: center;
  margin-top: 22px;
  font-weight: 400;
  font-size: 14px;
  color: #396afd;
}

.moreCss img {
  width: 18px;
  height: 13px;
}

.operateCss {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.operateCss_item {
  width: 48%;
  height: 145px;
  background: #f7fbff;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 30px 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 50px;
  margin-top: 20px;
}

.numCss {
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  z-index: 6;
}

.operateImgCss {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.operateCss_detail {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.operateCss_btn {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  color: #396afd;
}

.operateCss_btn img {
  width: 15px;
  height: 13px;
  margin-right: 10px;
}
</style>