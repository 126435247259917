var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"",staticClass:"saas_index_body"},[_c('div',{staticClass:"title_banner",attrs:{"id":"home"}},[_c('div',{staticClass:"title_banner_body"},[_c('el-carousel',{attrs:{"interval":5000,"height":"330px"}},[_c('el-carousel-item',[_c('div',{staticClass:"home_banner_bg"},[_c('img',{staticClass:"home_banner_bg_imgB",attrs:{"src":require("../assets/qyImg/cpjs.png")}})])])],1)],1),_vm._m(0)]),_c('div',{staticClass:"coreCss"},[_c('div',{staticClass:"coredetailCss"},[_vm._m(1),_c('div',{staticClass:"corelistCss"},_vm._l((_vm.coreList),function(item){return _c('div',{key:item.id,staticClass:"corelistCss_detail"},[_c('img',{staticClass:"coreImgCss",attrs:{"src":item.imgUrl,"alt":""}}),_c('div',{staticClass:"coreTextCss",staticStyle:{"top":"30px","left":"25px","font-size":"22px","color":"#36354B"}},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"coreTextCss",staticStyle:{"top":"70px","left":"25px"}},[_c('div',{staticStyle:{"font-weight":"350","font-size":"16px","color":"#8B8A95"}},[_vm._v(_vm._s(item.detail))]),_c('div',{staticStyle:{"font-weight":"350","font-size":"16px","color":"#8B8A95"}},[_vm._v(_vm._s(item.detailText1))]),_c('div',{staticStyle:{"font-weight":"350","font-size":"16px","color":"#8B8A95"}},[_vm._v(_vm._s(item.detailText2))]),_c('div',{staticStyle:{"font-weight":"350","font-size":"16px","color":"#8B8A95"}},[_vm._v(_vm._s(item.detailText3))])])])}),0)])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner_top"},[_c('div',{staticClass:"introduce"},[_c('span',{staticClass:"introduce_title1"},[_vm._v("全用工招聘、分销裂变、在线管理")]),_c('span',{staticClass:"introduce_title2"},[_vm._v("助您搭建属于自己的平台")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"corelist_tittle"},[_c('span',[_vm._v("三大")]),_c('span',{staticStyle:{"color":"#396AFD"}},[_vm._v("核心功能")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introCss"},[_c('div',{staticClass:"introDetailCss"},[_c('img',{staticStyle:{"width":"60%","height":"480px"},attrs:{"src":require("../assets/qyImg/zhaopin.png"),"alt":""}}),_c('div',{staticClass:"introDetailCss_left"},[_c('div',{staticStyle:{"font-weight":"700","font-size":"32px","color":"#396AFD"}},[_vm._v("全用工招聘")]),_c('div',{staticStyle:{"font-weight":"500","font-size":"20px","color":"#444444","margin-top":"15px"}},[_vm._v("搭建专属渠道，开启运营化招聘时代")]),_c('div',{staticClass:"intro_step",staticStyle:{"margin-top":"30px"}},[_c('img',{staticClass:"stepImg",attrs:{"src":require("../assets/qyImg/step1.png"),"alt":""}}),_c('div',{staticClass:"intro_stepDetail",staticStyle:{"border-bottom":"1px solid #eaedf5"}},[_vm._v("专属线上渠道，数据私有不流失")])]),_c('div',{staticClass:"intro_step",staticStyle:{"margin-top":"20px"}},[_c('img',{staticClass:"stepImg",attrs:{"src":require("../assets/qyImg/step2.png"),"alt":""}}),_c('div',{staticClass:"intro_stepDetail",staticStyle:{"border-bottom":"1px solid #eaedf5"}},[_vm._v("无限次发布/修改职位，实时同步更新")])]),_c('div',{staticClass:"intro_step",staticStyle:{"margin-top":"20px"}},[_c('img',{staticClass:"stepImg",attrs:{"src":require("../assets/qyImg/step3.png"),"alt":""}}),_c('div',{staticClass:"intro_stepDetail"},[_vm._v("页面操作、傻瓜式设计，兼顾易用性")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introCss",staticStyle:{"background":"#F8F9FA"}},[_c('div',{staticClass:"introDetailCss"},[_c('div',{staticClass:"introDetailCss_left"},[_c('div',{staticStyle:{"font-weight":"700","font-size":"32px","color":"#396AFD"}},[_vm._v("分销裂变")]),_c('div',{staticStyle:{"font-weight":"500","font-size":"20px","color":"#444444","margin-top":"15px"}},[_vm._v("社交人脉“滚雪球”，人人都是推广员")]),_c('div',{staticClass:"intro_step",staticStyle:{"margin-top":"30px"}},[_c('img',{staticClass:"stepImg",attrs:{"src":require("../assets/qyImg/step4.png"),"alt":""}}),_c('div',{staticClass:"intro_stepDetail",staticStyle:{"border-bottom":"1px solid #eaedf5"}},[_vm._v("所有小程序用户均可申请加入劳务经纪人")])]),_c('div',{staticClass:"intro_step",staticStyle:{"margin-top":"20px"}},[_c('img',{staticClass:"stepImg",attrs:{"src":require("../assets/qyImg/step5.png"),"alt":""}}),_c('div',{staticClass:"intro_stepDetail",staticStyle:{"border-bottom":"1px solid #eaedf5"}},[_vm._v("团队分销奖励体系利益驱动推广分享")])]),_c('div',{staticClass:"intro_step",staticStyle:{"margin-top":"20px"}},[_c('img',{staticClass:"stepImg",attrs:{"src":require("../assets/qyImg/step6.png"),"alt":""}}),_c('div',{staticClass:"intro_stepDetail"},[_vm._v("智能核算奖励生成报表，减少出错率")])])]),_c('img',{staticStyle:{"width":"60%","height":"480px"},attrs:{"src":require("../assets/qyImg/fenxiao.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introCss"},[_c('div',{staticClass:"introDetailCss"},[_c('img',{staticStyle:{"width":"60%","height":"480px"},attrs:{"src":require("../assets/qyImg/guanli.png"),"alt":""}}),_c('div',{staticClass:"introDetailCss_left"},[_c('div',{staticStyle:{"font-weight":"700","font-size":"32px","color":"#396AFD"}},[_vm._v("在线管理")]),_c('div',{staticStyle:{"font-weight":"500","font-size":"20px","color":"#444444","margin-top":"15px"}},[_vm._v("搭建专属渠道，开启运营化招聘时代")]),_c('div',{staticClass:"intro_step",staticStyle:{"margin-top":"30px"}},[_c('img',{staticClass:"stepImg",attrs:{"src":require("../assets/qyImg/step7.png"),"alt":""}}),_c('div',{staticClass:"intro_stepDetail",staticStyle:{"border-bottom":"1px solid #eaedf5"}},[_vm._v("支持在线无卡发薪/奖励、对公账号一键批量 发薪到微信")])]),_c('div',{staticClass:"intro_step",staticStyle:{"margin-top":"20px"}},[_c('img',{staticClass:"stepImg",attrs:{"src":require("../assets/qyImg/step8.png"),"alt":""}}),_c('div',{staticClass:"intro_stepDetail",staticStyle:{"border-bottom":"1px solid #eaedf5"}},[_vm._v("员工在线借支规范业务流程、清晰账目明细")])]),_c('div',{staticClass:"intro_step",staticStyle:{"margin-top":"20px"}},[_c('img',{staticClass:"stepImg",attrs:{"src":require("../assets/qyImg/step9.png"),"alt":""}}),_c('div',{staticClass:"intro_stepDetail"},[_vm._v("用户报名、入职离职等全周期管理、随时掌 控人员动态")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serviceCss"},[_c('div',{staticClass:"serviceDetailCss"},[_c('div',{staticClass:"corelist_tittle"},[_c('span',[_vm._v("六大")]),_c('span',{staticStyle:{"color":"#396AFD"}},[_vm._v("服务流程")])]),_c('div',{staticClass:"serve_list"},[_c('div',{staticClass:"serveContent1"},[_c('img',{attrs:{"src":require("../assets/qyImg/serve1.png"),"alt":""}}),_c('span',[_vm._v("小程序搭建")])]),_c('div',{staticClass:"serveContent2"},[_c('img',{attrs:{"src":require("../assets/qyImg/serve2.png"),"alt":""}}),_c('span',[_vm._v("线上产品操作培训")])]),_c('div',{staticClass:"serveContent1"},[_c('img',{attrs:{"src":require("../assets/qyImg/serve3.png"),"alt":""}}),_c('span',[_vm._v("小程序交付")])]),_c('div',{staticClass:"serveContent2"},[_c('img',{attrs:{"src":require("../assets/qyImg/serve4.png"),"alt":""}}),_c('span',[_vm._v("专业技术团队护航")])]),_c('div',{staticClass:"serveContent1"},[_c('img',{attrs:{"src":require("../assets/qyImg/serve5.png"),"alt":""}}),_c('span',[_vm._v("在线客服答疑")])]),_c('div',{staticClass:"serveContent2"},[_c('img',{attrs:{"src":require("../assets/qyImg/serve6.png"),"alt":""}}),_c('span',[_vm._v("产品详细操作手册")])])])])])
}]

export { render, staticRenderFns }