<template>
  <div class="saas_index_body">
    <div id="home" class="title_banner">
      <div class="title_banner_body">
        <el-carousel :interval="5000" height="560px">
          <el-carousel-item>
            <div class="home_banner_bg">
              <img class="home_banner_bg_imgB" src="../assets/qyImg/qy_banner.png" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="banner_top">
        <div class="introduce">
          <span class="introduce_title1">全用工管理系统</span>
          <div class="introduce_title2" style="margin-top:25px">
            <span style="font-weight:400">服务</span>
            <span style="font-weight:700">劳务派遣公司</span>
            <span style="font-weight:400">或</span>
            <span style="font-weight:700">人力资源机构</span>·
          </div>
          <span class="introduce_title2" style="color:#2A75FF;margin-top:20px">帮助其建立线上化招聘能力</span>
          <span class="introduce_btn">了解详情</span>
        </div>
      </div>

    </div>
    <div class="coreCss">
      <div class="coredetailCss">
        <div class="corelist_tittle">
          <span>三大</span>
          <span style="color:#396AFD">核心功能</span>
        </div>
        <div class="corelistCss">
          <div class="corelistCss_detail" v-for="item in coreList" :key="item.id">
            <img :src="item.imgUrl" alt="" class="coreImgCss">
            <div class="coreTextCss" style="top:30px;left:25px;font-size: 22px;color: #36354B;">{{item.title}}</div>
            <div class="coreTextCss" style="top:70px;left:25px;">
              <div  style="font-weight: 350;font-size: 16px;color: #8B8A95;">{{item.detail}}</div>
              <div  style="font-weight: 350;font-size: 16px;color: #8B8A95;">{{item.detailText1}}</div>
              <div  style="font-weight: 350;font-size: 16px;color: #8B8A95;">{{item.detailText2}}</div>
              <div  style="font-weight: 350;font-size: 16px;color: #8B8A95;">{{item.detailText3}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="introCss">
      <div class="introDetailCss">
        <img style="width:60%;height:480px" src="../assets/qyImg/zhaopin.png" alt="">
        <div class="introDetailCss_left">
          <div style="font-weight: 700;font-size: 32px;color: #396AFD;">全用工招聘</div>
          <div style="font-weight: 500;font-size: 20px;color: #444444;margin-top:15px">搭建专属渠道，开启运营化招聘时代</div>
          <div class="intro_step" style="margin-top:30px">
            <img src="../assets/qyImg/step1.png" alt="" class="stepImg">
            <div class="intro_stepDetail" style="border-bottom: 1px solid #eaedf5;">专属线上渠道，数据私有不流失</div>
          </div>
          <div class="intro_step" style="margin-top:20px">
            <img src="../assets/qyImg/step2.png" alt="" class="stepImg">
            <div class="intro_stepDetail" style="border-bottom: 1px solid #eaedf5;">无限次发布/修改职位，实时同步更新</div>
          </div>
          <div class="intro_step" style="margin-top:20px">
            <img src="../assets/qyImg/step3.png" alt="" class="stepImg">
            <div class="intro_stepDetail">页面操作、傻瓜式设计，兼顾易用性</div>
          </div>
        </div>
      </div>
    </div>
    <div class="introCss" style="background: #F8F9FA;">
      <div class="introDetailCss">
        <div class="introDetailCss_left">
          <div style="font-weight: 700;font-size: 32px;color: #396AFD;">分销裂变</div>
          <div style="font-weight: 500;font-size: 20px;color: #444444;margin-top:15px">社交人脉“滚雪球”，人人都是推广员</div>
          <div class="intro_step" style="margin-top:30px">
            <img src="../assets/qyImg/step4.png" alt="" class="stepImg">
            <div class="intro_stepDetail" style="border-bottom: 1px solid #eaedf5;">所有小程序用户均可申请加入劳务经纪人</div>
          </div>
          <div class="intro_step" style="margin-top:20px">
            <img src="../assets/qyImg/step5.png" alt="" class="stepImg">
            <div class="intro_stepDetail" style="border-bottom: 1px solid #eaedf5;">团队分销奖励体系利益驱动推广分享</div>
          </div>
          <div class="intro_step" style="margin-top:20px">
            <img src="../assets/qyImg/step6.png" alt="" class="stepImg">
            <div class="intro_stepDetail">智能核算奖励生成报表，减少出错率</div>
          </div>
        </div>
        <img style="width:60%;height:480px" src="../assets/qyImg/fenxiao.png" alt="">
      </div>
    </div>
    <div class="introCss">
      <div class="introDetailCss">
        <img style="width:60%;height:480px" src="../assets/qyImg/guanli.png" alt="">
        <div class="introDetailCss_left">
          <div style="font-weight: 700;font-size: 32px;color: #396AFD;">在线管理</div>
          <div style="font-weight: 500;font-size: 20px;color: #444444;margin-top:15px">搭建专属渠道，开启运营化招聘时代</div>
          <div class="intro_step" style="margin-top:30px">
            <img src="../assets/qyImg/step7.png" alt="" class="stepImg">
            <div class="intro_stepDetail" style="border-bottom: 1px solid #eaedf5;">支持在线无卡发薪/奖励、对公账号一键批量 发薪到微信</div>
          </div>
          <div class="intro_step" style="margin-top:20px">
            <img src="../assets/qyImg/step8.png" alt="" class="stepImg">
            <div class="intro_stepDetail" style="border-bottom: 1px solid #eaedf5;">员工在线借支规范业务流程、清晰账目明细</div>
          </div>
          <div class="intro_step" style="margin-top:20px">
            <img src="../assets/qyImg/step9.png" alt="" class="stepImg">
            <div class="intro_stepDetail">用户报名、入职离职等全周期管理、随时掌 控人员动态</div>
          </div>
        </div>
      </div>
    </div>
    <div class="serviceCss">
      <div class="serviceDetailCss">
        <div class="corelist_tittle">
          <span>六大</span>
          <span style="color:#396AFD">服务流程</span>
        </div>
        <div class="serve_list">
          <div class="serveContent1">
            <img src="../assets/qyImg/serve1.png" alt="">
            <span>小程序搭建</span>
          </div>
          <div class="serveContent2">
            <img src="../assets/qyImg/serve2.png" alt="">
            <span>线上产品操作培训</span>
          </div>
          <div class="serveContent1">
            <img src="../assets/qyImg/serve3.png" alt="">
            <span>小程序交付</span>
          </div>
          <div class="serveContent2">
            <img src="../assets/qyImg/serve4.png" alt="">
            <span>专业技术团队护航</span>
          </div>
          <div class="serveContent1">
            <img src="../assets/qyImg/serve5.png" alt="">
            <span>在线客服答疑</span>
          </div>
          <div class="serveContent2">
            <img src="../assets/qyImg/serve6.png" alt="">
            <span>产品详细操作手册</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="applyCss">
      <div style="font-size: 30px;color: #FFFFFF;">立即申请试用产品</div>
      <div class="applyTextCss">申请试用</div>
    </div> -->
  </div>
</template>


<script>
import aEntityAPI from "@/api/sysAPI.js";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters({
      sysConfig: "config/sysConfig",
    }),
    ...mapActions(["config/setConfig"]),
  },

  data() {
    return {
     coreList: [
        {
          id: 1,
          imgUrl: require("@/assets/qyImg/core1.png"),
          title: "全用工招聘",
          detail: "专属数字化招聘渠道，聚拢全",
          detailText1:"用工私域流量配置专属后",
          detailText2:'台，确保数据安全私密'
        },
        {
          id: 2,
          imgUrl: require("@/assets/qyImg/core2.png"),
          title: "分销裂变",
          detail: "人脉“滚雪球”，以人带人人",
          detailText1:"人都是平台推广员",
        },
        {
          id: 3,
          imgUrl: require("@/assets/qyImg/core3.png"),
          title: "在线管理",
          detail: "员工管理SaaS化，实时掌握",
          detailText1:"员工动态，支持在线发放工资、",
          detailText2:'奖金、借支智能生成报表,',
          detailText3:'一键导出明细'
        }
      ]
    };
  },

  created() {
    this["config/setConfig"];
  },

  watch: {},

  mounted() {
    
  },

  methods: {
   
  },

  filters: {
    //文字数超出时，超出部分使用...
    ellipsisTitle(value) {
      if (!value) return "";
      if (value.length > 15) {
        return value.slice(0, 15) + "...";
      }
      return value;
    },

    ellipsisRemark(value) {
      if (!value) return "";
      if (value.length > 35) {
        return value.slice(0, 35) + "...";
      }
      return value;
    },
  },
};
</script>

<style >
.betweenCss {
  justify-content: space-between;
}
.saas_index_body {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.saas_index_body .home_banner_bg {
  width: 100%;
  height: 100%;
  position: relative;
}

.saas_index_body .home_banner_bg_imgA {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.saas_index_body .home_banner_bg_imgB {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  mix-blend-mode: screen;
  z-index: 4;
  /* object-fit: contain; */
}

.saas_index_body .home_banner_bg_imgC {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  object-fit: contain;
}

.saas_index_body .title_banner {
  padding-top: 68px;
  height: 560px;
  background-color: #ffffff;
  position: relative;
}

.saas_index_body .discount {
  height: 250px;
  background-color: #0f68e8;
}

.saas_index_body .product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.saas_index_body .title_banner_body {
  width: 100%;
  height: 100%;
  z-index: 2;
}
.banner_top {
  width: 100%;
  position: absolute;
  top: 180px;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  padding-left: 360px;
  padding-right: 160px;
  box-sizing: border-box;
}

.introduce {
  /* width: 450px !important; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
}

.introduce_title1 {
  font-size: 58px;
  color: #396afd;
  font-family: douyu;
}

.introduce_title2 {
  font-weight: 400;
  font-size: 20px;
  color: #2a75ff;
}

.introduce_btn {
  margin-top: 45px;
  width: 182px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(24, 57, 232, 0.1);
  border-radius: 63px;
  font-weight: 500;
  font-size: 20px;
  color: #396afd;
  text-align: center;
  line-height: 60px;
}

.saas_index_body .cust_case {
  background: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.saas_index_body .cust_title {
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 30px;
  margin-bottom: 30px;
}

.saas_index_body .cust_body {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
  width: 1200px;
  height: 360px;
}
.saas_index_body .cust_body .el-carousel__arrow--left {
  left: 0;
}
.saas_index_body .cust_body .el-carousel__arrow--right {
  right: 0;
}
.saas_index_body .img_array_body {
  display: flex;
  /* background-color: #0f68e8; */
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  color: #0f68e8;
  height: 100%;
  width: 1100px;
  margin-left: 50px;
}

.saas_index_body .img_array_body_item {
  width: 230px;
  height: 90px;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  border: 1px solid #ddd;
  padding: 20px 10px;
}
.coreCss {
  width: 100%;
  height: 490px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../assets/qyImg/hexin.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.coredetailCss {
  width: 1200px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 75px 0;
}

.corelist_tittle {
  font-weight: 700;
  font-size: 38px;
  color: #000000;
}

.corelistCss {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.corelistCss_detail {
  width: 33.3%;
  height: 250px;
  position: relative;
}

.coreImgCss {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.coreTextCss {
  width: 80%;
  position: absolute;
  z-index: 2;
  font-weight: 700;
}

.introCss {
  width: 100%;
  height: 730px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.introDetailCss {
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.introDetailCss_left {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.intro_step {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.stepImg {
  width: 60px;
  height: 60px;
}

.intro_stepDetail {
  width: calc(100% - 60px - 25px);
  height: 60px;
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: 350;
  color: #444444;
}

.serviceCss {
  width: 100%;
  height: 490px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
}

.serviceDetailCss {
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.serve_list {
  width: 100%;
  height: 240px;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.serveContent1 {
  width: 170px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 18px;
  color: #4151fb;
}

.serveContent2 {
  width: 170px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: #43bcf9;
}

.serveContent1 img {
  width: 100%;
  height: 165px;
}

.serveContent2 img {
  width: 100%;
  height: 165px;
}

.serveContent1 span {
  margin-right: 12px;
  margin-top: -20px;
}

.serveContent2 span {
  margin-right: 12px;
  margin-top: -20px;
}

.applyCss {
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/qyImg/shenqing.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-weight: 400;
}

.applyTextCss {
  width: 140px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #ffffff;
  border-radius: 40px;
  font-size: 16px;
  color: #333333;
  margin-top: 25px;
}
</style>